<!-- Localized -->
<template>
<!-- Component is based on: https://codepen.io/ditarahma08/pen/GRRxZLW -->
<div v-show="isOpen" class="web-camera-container w-full flex flex-col items-center justify-center">
  
  <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ 'flash' : isShotPhoto }">
    
    <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>
      
    <video v-show="!isPhotoTaken" class="camera-shutter-video" ref="camera" :width="450" :height="337.5" autoplay></video>
    
    <canvas v-show="isPhotoTaken" id="photoTaken" class="camera-shutter-canvas" ref="canvas" :width="450" :height="337.5"></canvas>
  </div>
  
  <div v-if="isCameraOpen && !isLoading && !isPhotoTaken" class="camera-shoot">
    <button
      type="button"
      class="cursor-pointe border border-gray-700 w-20 min-w-min px-3 py-1 bg-white hover:bg-gray-100 cursor-pointer rounded-lg text-sm text-black flex justify-center items-center font-semibold"
      @click="takePhoto"
    >
      {{ $t('form_builder.capture') }}
    </button>
  </div>
  
  <div v-if="isPhotoTaken && isCameraOpen" class="camera-download flex gap-2 mt-2">
    <button
      type="button"
      class="cursor-pointe border border-gray-700 w-20 min-w-min px-3 py-1 bg-white hover:bg-gray-100 cursor-pointer rounded-lg text-sm text-black flex justify-center items-center font-semibold"
      @click="redoPhoto"
    >
      {{ $t('form_builder.delete') }}
    </button>
    <a
      id="submitPhoto"
      download="my-photo.jpg"
      class="cursor-pointe border border-gray-700 w-20 min-w-min px-3 py-1 bg-gray-600 hover:bg-gray-700 hover:bg-gray-100 cursor-pointer rounded-lg text-sm text-white-text flex justify-center items-center font-semibold whitespace-nowrap"
      role="button"
      @click="submitPhoto"
    >
      {{ $t('form_builder.submit') }}
    </a>
  </div>
</div>

</template>

<script>
export default {
  name: 'camera-field',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    extension: {
      type: String,
      default: 'png',
    },
  },
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: '#',
      photoFile: null,
      resolvePromise: undefined,
      rejectPromise: undefined,
    }
  },
  beforeDestroy() {
    if(this.isCameraOpen) {
      this.closeCamera();
    }
  },
  methods: {
    openCamera() {
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
          this.resolvePromise = resolve;
          this.rejectPromise = reject;
          if (!this.isCameraOpen) this.toggleCamera(true);
      });
    },
    async toggleCamera() {
      if(this.isCameraOpen) {
        this.closeCamera();
      } else {
        try {
          this.isCameraOpen = true;
          await this.createCameraElement();
          this.resolvePromise(true);
        } catch (error) {
          this.isCameraOpen = false;
          this.resolvePromise(false);
        }
      }
      return this.isCameraOpen;
    },
    closeCamera() {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
    },

    setPhotoFile(payload) {
      this.photoFile = payload || null;
      this.$emit('input', this.photoFile);
    },
    
    async createCameraElement() {
      this.isLoading = true;
      
      const constraints = (window.constraints = {
				audio: false,
				video: true
			});


			await navigator.mediaDevices
				.getUserMedia(constraints)
				.then(stream => {
          this.isLoading = false;
					this.$refs.camera.srcObject = stream;
				})
				.catch(error => {
          this.isLoading = false;
          console.log('Camera error', error);
					alert("May the browser didn't support or there is some errors.");
          throw error;
				});
    },
    
    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject?.getTracks();

      if (tracks) {
        tracks.forEach(track => {
          track.stop();
        });
      }
    },
    
    takePhoto() {
      if(!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }
      
      this.isPhotoTaken = !this.isPhotoTaken;
      
      const context = this.$refs.canvas.getContext('2d');
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
    },
    redoPhoto() {
      this.isPhotoTaken = !this.isPhotoTaken;
      
      const context = this.$refs.canvas.getContext('2d');
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
    },
    
    async submitPhoto() {
      const canvas = document.getElementById("photoTaken").toDataURL(`image/${this.extension}`)
        // .replace("image/jpeg", "image/octet-stream");

      this.setPhotoFile(canvas);
    },

    // urlToFile(url, filename, mimeType){
    //   return (fetch(url)
    //     .then(function(res){return res.arrayBuffer();})
    //     .then(function(buf){return new File([buf], filename,{type:mimeType});})
    //   );
    // },
  }
}
</script>

<style scoped lang="scss">
body {
  display: flex;
  justify-content: center;
}
.camera-shutter-video {
  height: 337.5px !important;
  width: 450px !important;
}
.web-camera-container {
  .camera-button {
    margin-bottom: 2rem;
  }
  
  .camera-box {  
    height: 337.5px;  
    .camera-shutter {
      opacity: 0;
      width: 450px !important;
      height: 337.5px !important;
      background-color: #fff;
      position: absolute;
      
      &.flash {
        opacity: 1;
      }
      .camera-shutter-video {
        height: 337.5px !important;
        width: 450px !important;
      }
    }
  }
  
  .camera-shoot {
    margin: 1rem 0;
    
    button {
      height: 80px;
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      
      img {
        height: 35px;
        object-fit: cover;
      }
    }
  }
}
@media screen and (min-width: 450px) and (max-width: 600px){
  .camera-shutter-canvas {
        width: 290px!important;
        height: 337px !important;
      }
  .web-camera-container {
  .camera-button {
    margin-bottom: 2rem;
  }
  
  .camera-box {  
    height: 337.5px;  
    .camera-shutter {
      opacity: 0;
      width: 390px !important;
      height: 337.5px !important;
      background-color: #fff;
      position: absolute;
      
      &.flash {
        opacity: 1;
      }
      .camera-shutter-video {
        height: 337.5px !important;
        width: 450px !important;
      }
      .camera-shutter-canvas {
        width: 390px !important;
      }
    }
  }
}
}
@media screen and (min-width: 350px) and (max-width: 449px){
  .camera-shutter-canvas {
    width: 279px!important;
    height: 337.5px !important;
  }
  .web-camera-container {
  .camera-button {
    margin-bottom: 2rem;
  }
  
  .camera-box {  
    height: 337.5px;  
    .camera-shutter {
      opacity: 0;
      width: 350px !important;
      height: 337.5px !important;
      background-color: #fff;
      position: absolute;
      
      &.flash {
        opacity: 1;
      }
      .camera-shutter-video {
        height: 337.5px !important;
        width: 450px !important;
      }
      .camera-shutter-canvas {
        width: 350px !important;
      }
    }
  }
}
}
@media screen and (min-width: 300px) and (max-width: 349px){
  .camera-shutter-canvas {
        width: 200px!important;
        height: 305px !important;
      }
  .web-camera-container {
  .camera-button {
    margin-bottom: 2rem;
  }
  
  .camera-box {  
    height: 337.5px;  
    .camera-shutter {
      opacity: 0;
      width: 200px !important;
      height: 337.5px !important;
      background-color: #fff;
      position: absolute;
      
      &.flash {
        opacity: 1;
      }
      .camera-shutter-video {
        height: 337.5px !important;
        width: 450px !important;
      }
      .camera-shutter-canvas {
        width: 200px !important;
      }
    }
  }
}
}
</style>